<template>
  <div id="bookmarklet" class="md-card md-layout-item md-size-100 fave-card">
    <div id="logo-header-container">
      <img id="logo" :src="`${publicPath}wibki.png`" alt="wibki header logo">
    </div>
    <div class="md-alignment-center-center main-container">
      <form novalidate class="md-layout md-alignment-center-center" @submit.prevent="validateUser">
        <md-card class="md-layout-item md-size-50 md-small-size-100">
          <md-card-header>
            <div class="md-title bookmark-title">{{ $t('bookmarklet.title') }}</div>
          </md-card-header>
          <md-card-content class="bookmark-content">
            <md-field :class="[getValidationClass('title'),'md-field-auth']">
              <md-input name="title"
                        id="title"
                        v-model="form.title"
                        :disabled="isLoading"
                        @focus="clearErrors('title')" class="md-input-auth input-with-border" placeholder="title"/>
              <div class="md-error" v-if="!$v.form.title.required">
                {{ $t('bookmarklet.titleRequired') }}
              </div>
            </md-field>
            <md-field :class="[getValidationClass('link'),'md-field-auth']">
              <md-input name="link"
                        id="link"
                        v-model="form.link"
                        :disabled="isLoading" class="md-input-auth input-with-border" placeholder="link"/>
              <div class="md-error" v-if="!$v.form.link.required">
                Link Required
              </div>
            </md-field>
            <select class="form-select select-tab-bookmark" name="tabs" id="tabs" v-model="form.tabId">
              <option value="select" disabled>Select tab to add</option>
              <option v-for="(tab, key) in userTabs" :key="key" :value="tab.id">{{ tab.name }}</option>
            </select>
            <md-field :class="[getValidationClass('tab'),'md-field-auth']" v-if="newtabInput">
              <md-input name="tab"
                        id="tab"
                        v-model="form.newTab"
                        :disabled="isLoading" class="md-input-auth input-with-border" placeholder="Tab Name" required/>
            </md-field>
            <a id="new-tab-btn" @click="createNewTab">
              {{ $t('bookmarklet.addNewTab') }}
            </a>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="isLoading"/>
          <md-card-actions class="button-container">
            <md-button type="submit" class="md-raised submit-bookmark-btn">
              {{ $t('bookmarklet.addBookmark') }}
            </md-button>
          </md-card-actions>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import formHelpersMixin from '@/mixins/formHelpersMixin';
import loggedInUserMixin from '@/mixins/loggedInUserMixin';
import loaderMixin from '@/mixins/loaderMixin';
import { CREATE_TAB_CELL, CREATE_USER_TAB } from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';
import { CHECK_SITE } from '@/store/modules/admin/single/site/siteActions.type';

export default {
  name: 'Bookmarklet',
  mixins: [
    validationMixin,
    formHelpersMixin,
    loggedInUserMixin,
    loaderMixin,
  ],
  data: () => ({
    publicPath: process.env.BASE_URL,
    form: {
      title: null,
      link: null,
      tabId: 'select',
      siteId: null,
      fileId: null,
      image: null,
      newTab: null,
    },
    newtabInput: false,
  }),
  validations: {
    form: {
      title: {
        required,
      },
      link: {
        required,
      },
      tabId: {
        required,
      },
    },
  },
  computed: {
    userTabs() {
      return this.$store.getters.userTabs;
    },
  },
  mounted() {
    this.form.link = this.$route.query.link
      ? this.$route.query.link
      : (this.$route.query.url ?? null);
    this.form.title = this.$route.query.title ?? 'Unknown';
    this.checkSite();
  },
  methods: {
    createNewTab() {
      this.newtabInput = true;
    },
    getHostname(url) {
      // eslint-disable-next-line no-useless-escape
      const urlParts = /^((http[s]?|ftp):\/\/)?\/?([^\/\.]+\.)*?([^\/\.]+\.[^:\/\s\.]{2,3}(\.[^:\/\s\.]{2,3})?)(:\d+)?($|\/)([^#?\s]+)?(.*?)?(#[\w\-]+)?$/.exec(url);
      if (urlParts) {
        const hostname = urlParts[4];
        const name = /(.*)\.[^.]+$/.exec(hostname);
        return name[1];
      }
      return false;
    },
    clearForm() {
      this.$v.$reset();
      this.form.title = null;
      this.form.link = null;
      this.form.tab = null;
    },
    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveCell();
      }
    },
    checkSite() {
      const filters = {
        link: this.form.link,
      };
      this.$store.dispatch(CHECK_SITE, filters)
        .then((response) => {
          const item = response.data.site ?? null;
          this.form.link = filters.link;
          this.form.siteId = item ? item.id : null;
          this.form.fileId = item ? item.fileId : null;
          this.form.file = item ? item.public_path : `${this.publicPath}Wibki-logo.png`;
          this.form.image = item ? item.public_path : `${this.publicPath}Wibki-logo.png`;
          this.$log.info('CHECK_SITE', response);
        })
        .catch((error) => {
          this.$log.error('CHECK_SITE', error);
        });
    },
    saveCell() {
      if (this.newtabInput) {
        if (this.newTab !== null) {
          this.saveNewTab();
        }
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.form.tabId !== null && this.form.tabId !== 'select') {
          const payload = {
            id: this.form.id ?? null,
            // eslint-disable-next-line no-nested-ternary
            tab_id: this.form.tabId,
            site_id: this.form.siteId ?? null,
            link: this.form.link ?? null,
            alt: this.form.title ?? null,
            file_id: this.form.fileId ?? null,
            drag: false,
            bookmarklet: true,
            source: 'wibki',
          };
          // Same store dispatch used for update and create using ternary operator
          this.$store.dispatch(CREATE_TAB_CELL, payload)
            .then((response) => {
              this.$log.info(CREATE_TAB_CELL, payload);
              this.$toasted.show(response.message, {
                type: 'success',
                position: 'top-right',
                duration: 5000,
                icon: 'check',
              });
              this.clearForm();
              this.cell = null;
              this.timeout = setTimeout(() => {
                window.close();
              }, 1200);
            })
            .catch((error) => {
              this.$log.error(CREATE_TAB_CELL, error);
              this.$toasted.show(error.message, {
                type: 'error',
                position: 'top-right',
                duration: 5000,
                icon: 'alert',
              });
            });
        } else {
          this.$toasted.show('Unable to add cell.', {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
        }
      }, 800);
    },
    saveNewTab() {
      const payload = {
        name: this.form.newTab,
        privatetab: 1,
      };
      this.$store.dispatch(CREATE_USER_TAB, payload)
        .then((response) => {
          this.$log.info(CREATE_USER_TAB, payload);
          this.$toasted.show(response.message, {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.form.tabId = response.data.userTab.id ?? null;
          this.newTab = null;
        })
        .catch((error) => {
          this.$log.error(CREATE_USER_TAB, error);
          this.$toasted.show(error.message, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
  },
};
</script>

<style lang="scss">
.profile-menu {
  background: #FFFFFF !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22) !important;
  border-radius: 12px !important;
  max-height: unset !important;

  .md-menu-content-container {
    border-radius: 12px !important;
  }

  .md-list-item-button {
    color: #D00A0A !important;
  }
}

@import '@/assets/scss/layout/rec-header.scss';
@import '@/assets/scss/layout/bookmarklet.scss';
</style>
