<template>
  <div>
    <div class="md-layout-item info-message-wrapper" v-if="message && this.infoMessage">
    <span v-html="this.message"></span>
    <button type="button" class="close-btn" @click="closeInfoMessage">X</button>
  </div>
  <amf-page id="my-faves" :hasBreadcrumbs="false" :hasSidebar="false">
    <template #content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 user-top-tabs-faves">
          <UserTopTabs :isDifferentUser="isDifferentUser" :publicUsername="publicUsername"></UserTopTabs>
        </div>
      </div>
    </template>
  </amf-page>
  </div>
</template>
<script>
import AmfPage from '@/components/page-templates/AmfPage.vue';
import loaderMixin from '@/mixins/loaderMixin';
import { FETCH_USER_BY_NAME } from '@/store/actions.type';
import { FETCH_USER_PAGE_MSG_A } from '@/store/modules/admin/single/setting/settingActions.type';
import UserTopTabs from '@/components/common/UserTopTabs.vue';

export default {
  name: 'MyFaves',
  components: {
    AmfPage,
    UserTopTabs,
  },
  mixins: [
    loaderMixin,
  ],
  props: {
    username: {
      type: String,
    },
  },
  data: () => ({
    publicUsername: null,
    publicPath: process.env.BASE_URL,
    mostPopularSitesImageStripe: null,
    infoMessage: true,
    isDifferentUser: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    message() {
      return this.$store.getters.userPageMsg;
    },
    loggedUsername() {
      return this.user ? this.user.username : null;
    },
    selectedTabObj() {
      if (!this.$store.getters.userTabs) {
        return {};
      }
      return this.$store.getters.userTabs[0];
    },
    weeklyLine() {
      return this.$store.getters.weeklyLine != null && this.$store.getters.weeklyLine.line != null ? this.$store.getters.weeklyLine.line : { cells: [] };
    },
  },
  mounted() {
    this.checkDifferentUser();
  },
  watch: {
    username: {
      handler(val) {
        if (val) {
          this.checkIfUserExists();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    link(address) {
      return `https://${address}`;
    },
    checkDifferentUser() {
      this.$store.dispatch(FETCH_USER_PAGE_MSG_A);
      if (this.username !== this.loggedUsername) {
        this.isDifferentUser = true;
      } else {
        this.isDifferentUser = false;
      }
    },
    checkIfUserExists() {
      if (this.username) {
        this.$store.dispatch(FETCH_USER_BY_NAME, this.username)
          .then((response) => {
            this.publicUsername = response.data.user;
            this.$log.info('FETCH_USER_BY_NAME', response);
          })
          .catch((error) => {
            this.$toasted.show(error, {
              type: 'error',
              position: 'top-right',
              duration: 5000,
              icon: 'alert',
            });
            this.$router.push('/');
            this.$log.error('FETCH_USER_BY_NAME', error);
          });
      } else {
        this.$router.push('/');
      }
    },
    closeInfoMessage() {
      this.infoMessage = false;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/layout/myfaves.scss';
</style>
